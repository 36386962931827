type MqttTopicsVideo = {
    bioscopeStatus: string;
    videoStatus: string;
    bioboardData: string;
};

const mqttTopicsVideo = (bioscopeId: number | string): MqttTopicsVideo => {
    return {
        bioscopeStatus: `bioscope/status/${bioscopeId}`,
        videoStatus: `bioscope/livestream/${bioscopeId}/status`,
        bioboardData: `bioscope/data/response/${bioscopeId}`,
    };
};

export { mqttTopicsVideo };
