import { useCallback } from 'react';

import { GenericPage } from '@/components/molecules/GenericSidebar';

import { useCage } from '../contexts/cage-context';
import { LICENSES } from '@/config/licenses';

import useCheckPageFeatureFlags from './check-page-feature-flags';
import useCheckPageRoles from './check-page-roles';

function useCageIsPageAvailable() {
    const { hasCageLicense } = useCage();
    const checkPageRoles = useCheckPageRoles();
    const checkPageFeatureFlags = useCheckPageFeatureFlags();

    const checkPageLicenses = (page: GenericPage): boolean => {
        if (!page.licenses || page.licenses.length === 0) {
            return true; // No licenses required
        }
        const hasValidLicenses = page.licenses.some((license) => hasCageLicense(license));

        return hasValidLicenses;
    };

    return useCallback(
        (page) =>
            [checkPageRoles, checkPageLicenses, checkPageFeatureFlags].every((check) =>
                check(page)
            ),
        [checkPageFeatureFlags, checkPageLicenses, checkPageRoles]
    );
}

export default useCageIsPageAvailable;
export { useCageIsPageAvailable };
